import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Fonts from './Fonts'
import Footer from './Footer'
import '../main.css'
import './layout.css'

const Layout = ({ article, children, title, url }) => {
  return (
    <>
      <Fonts />
      <Header article={article} title={title} url={url} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
