import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

const FakeTertiaryLink = ({ className, text, white }) => (
  <span
    className={classNames(
      {
        'text-15 cursor-pointer flex items-start focus:outline-none focus:shadow-none transition-all duration-200 ease-in-out': true,
        'text-blue-900 hover:text-blue-500 active:text-blue-200 focus:text-blue-200': !white,
        'text-white': white
      },
      className
    )}
  >
    <span className="mr-1 uppercase inline-block">{text}</span>
    <IosArrowForward color="currentColor" />
  </span>
)

FakeTertiaryLink.propTypes = {
  text: PropTypes.string.isRequired
}

export default FakeTertiaryLink
