import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import useWindowWidth from '../hooks/useWindowWidth'
import { isTouchDevice } from '../utils/generalUtils'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

const isTouch = isTouchDevice()

const Header = ({ article, title, url }) => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        headerContent: globalSet (handle: "Header") {
          ... on Gatsby_header_GlobalSet {
            modalBlock {
              ... on Gatsby_modalBlock_modal_BlockType {
                modalHook
                modalTitle
                modalHeadline
                modalText
              }
            }
          }
        }
      }
    }
  `)

  const { headerContent } = data.gatsby
  const width = useWindowWidth()

  return (
    <header className="sticky top-0 z-30 bg-white">
      {width > 1366 || (width > 1024 && !isTouch) ? (
        <DesktopHeader article={article} title={title} url={url} headerContent={headerContent} />
      ) : (
        <MobileHeader article={article} headerContent={headerContent} />
      )}
    </header>
  )
}

export default Header
