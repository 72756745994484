import React from 'react'
import { Link } from 'gatsby'

const LogoHeader = ({ className }) => {
  return (
    <Link to="/" className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 669.57 84.31"
        className="w-32"
      >
        <g fill="#1d1d1b">
          <path d="M24.9 2.1L55 65.35V2.11h16v80.34H47.35L16.22 15.78v66.67H0V2.11z" />
          <path d="M129.34 2.1l27.9 80.35H139.9l-5.78-17.33h-31.77l-6 17.33H79.88l29-80.34zM106.8 52.13h23L118.67 17.9z" />
          <path d="M376.7 2.22V15.1h-34.56v19.78h21.43v13.34h-21.43v34.22h-17.22V2.22z" />
          <path d="M484.53 2.22v66.57h37.35v13.66h-54.91V2.22z" />
          <path d="M630.27 47.45h21.4V34.34h-21.4V15.22h.02V2.11h-17.24v80.34h56.52V69.12h-39.3z" />
          <path d="M645.6 2.1l23.97 24.02V2.1z" />
          <path d="M418.75 83.71h.09l.7-.03c15.4-.82 27.33-9.42 32.95-22.66a43.4 43.4 0 003.97-18.32 43.94 43.94 0 00-2.54-14.85h.02C449.15 12.42 436.52 2.5 419.38 1.7h-.14l-.61-.03-.58-.01h-1.65c-.25 0-.5 0-.75.02h-.1l-.7.03c-15.4.82-27.32 9.42-32.95 22.66a43.4 43.4 0 00-3.97 18.33 43.88 43.88 0 002.55 14.85h-.03c4.8 15.43 17.42 25.33 34.57 26.14h.13l.62.02.58.01h1.64c.26-.02.51-.02.76-.02zM393.33 42.7a30.26 30.26 0 013.79-14.74h.03l.3-.59c4.26-7.2 11.42-12 19.67-12a22.5 22.5 0 019.16 1.9 24.92 24.92 0 0110.82 10.2l.19.37a30.84 30.84 0 01.01 29.6h-.02l-.31.59c-4.26 7.2-11.42 12-19.67 12a22.5 22.5 0 01-9.16-1.9 24.93 24.93 0 01-10.82-10.2l-.19-.37h-.01a30.48 30.48 0 01-3.79-14.86z" />
          <path d="M598.3 39.77H565.5v.03h-.3v13.34h15.55v15.95a46.1 46.1 0 01-14.66 1.62c-4.04-.23-7.92-.89-10.34-2.08a24.04 24.04 0 01-8.22-6.45 31.84 31.84 0 01-6.56-19.2c0-9.54 3.85-18.7 9.86-24.1a19.96 19.96 0 0114.48-5.6 21.87 21.87 0 019.15 1.6c4.6 2.13 6.83 6.8 7.06 12a1.84 1.84 0 00.66.03v-.05h1.34a12.55 12.55 0 011.99-.02h.01l.02.02 13.88.02a25.7 25.7 0 00-3.18-13.09 7.4 7.4 0 00-1.3-2.02C588.3 4.16 578.71.57 567 .04h-.14l-.63-.03-.6-.01h-1.7l-.77.02h-.1c-.25 0-.48.01-.72.03-15.95.84-28.3 9.65-34.13 23.23a44.09 44.09 0 00-4.1 18.78 44.57 44.57 0 002.63 15.22h-.02c4.82 15.34 17.26 25.36 34.22 26.7q3.78.32 8.05.32c10.9 0 20-1.22 29.34-4.44V52.89z" />
          <path d="M262.2 2.06l-.1 32.12 30.33.09.1-32.12 17.45.06-.25 80.34-17.23-.05.1-35.23-30.55-.09-.1 35.23-17.23-.06.25-80.34z" />
          <path d="M197.29 83.74c-22.03 0-39.17-18.03-39.17-41.05S175.27 1.64 197.3 1.64c18.3 0 31.81 10.09 36.83 26.2h-16.67a22.33 22.33 0 00-20.17-12.48c-13.3 0-23.78 12.47-23.78 27.33 0 14.97 10.49 27.44 23.78 27.44a22.66 22.66 0 0020.17-12.7H234c-5.13 15.99-18.77 26.3-36.71 26.3" />
        </g>
      </svg>
    </Link>
  )
}

export default LogoHeader
