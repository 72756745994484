import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormWithEmailInput from './FormWithEmailInput';

export const Modal = ({ form, headline, onClose, text, title, url, wide }) => {
  const background = React.createRef();
  const [visible, toggleVisibility] = useState(true);

  const handleClick = event => {
    event.preventDefault();
    toggleVisibility(false);
    setTimeout(onClose, 500);
  };

  return (
    <div
      className={classNames({
        'text-black-900 fixed inset-0 flex align-middle justify-center z-30 mx-auto transform transition-all ease-in-out duration-500 animate-opacity-0-to-1': true,
        'opacity-100': visible,
        'opacity-0': !visible,
      })}
      role="dialog"
    >
      <div
        className={classNames({
          'w-11/12 h-auto my-auto z-50 px-8 pt-2 pb-16 max-w-4xl shadow-2xl bg-grey-900.95 transform transition-all duration-500 ease-in-out sm:w-4/6 lg:w-3/6 sm:px-12 animate-translate-y-top-full-animation': true,
          'translate-y-0': visible,
          '-translate-y-full': !visible,
        })}
      >
        <button
          onClick={handleClick}
          className="block ml-auto text-5xl font-light trasnform transition-colors duration-150 active:text-blue-200 focus:outline-none hover:text-blue-500"
        >
          ×
        </button>
        <h2 className="text-3.5xl font-normal mb-8 md:text-4.5xl md:w-4/6">
          {title}
        </h2>
        {headline && (
          <h4 className="text-17 font-normal mb-4 md:w-4/6">{headline}</h4>
        )}
        <p className="text-15 md:w-5/6 lg:w-4/6">{text}</p>
        {form && url && (
          <div className="mt-15 sm:mt-24 ">
            <FormWithEmailInput wide={wide} url={url} />
          </div>
        )}
      </div>
      <button
        className="fixed w-full z-30 bg-grey-500.5 block inset-0 outline-none cursor-default"
        onMouseDown={handleClick}
        ref={background}
        aria-label="Close modal"
      />
    </div>
  );
};

Modal.propTypes = {
  form: PropTypes.bool,
  headline: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  wide: PropTypes.bool,
};

export default Modal;
