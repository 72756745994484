import { useEffect, useState } from 'react'

import { debounce } from '../utils/generalUtils'

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(undefined)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWindowWidth(window.innerWidth)

      const debouncedHandleResize = debounce(handleResize, 200)

      window.addEventListener('resize', debouncedHandleResize)

      handleResize()

      return () => window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [windowWidth])
  return windowWidth
}

export default useWindowWidth
