import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ModalTrigger from '../HOC/ModalTrigger';
import IosCall from 'react-ionicons/lib/IosCall';
import IosAtOutline from 'react-ionicons/lib/IosAtOutline';

const DesktopFooterMenu = ({ categories, tools, more, footerContent }) => {
  const { contactBlock, modalBlock } = footerContent

  return (
    <section className="bg-black-700 py-16 px-5 md:px-12 lg:px-24 xl:px-5 hidden md:block">
      <div className="flex items-start m-auto max-w-screen-xl">
        <div className="w-1/3 xl:w-1/4">
          <strong className="block mb-4">Themen</strong>
          <nav>
            <ul>
              {categories.map(category => (
                <li className="mt-6" key={category.id}>
                  <Link
                    className="link link-padded ease-in-out duration-150"
                    to={`/${category.uri}`}
                  >
                    {category.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="relative flex flex-col xl:flex-row w-1/3 xl:w-1/2">
          <div className="w-full xl:w-6/12">
            <strong className="block mb-4">Unser Angebot</strong>
            <nav>
              <ul>
                {tools.map(tool => (
                  <li className="mt-6" key={tool.id}>
                    <a
                      className="link link-padded transform ease-in-out duration-150"
                      href={tool.uniformResourceLocator}
                    >
                      {tool.title}
                    </a>
                  </li>
                ))}
                <ModalTrigger
                  form
                  wide={false}
                  url={modalBlock[0].modalHook}
                  title={modalBlock[0].modalTitle}
                  headline={modalBlock[0].modalHeadline}
                  text={modalBlock[0].modalText}
                >
                  <li className="mt-6">
                    <span className="cursor-pointer link link-padded transform ease-in-out duration-150">
                      {modalBlock[0].modalTitle}
                    </span>
                  </li>
                </ModalTrigger>
              </ul>
            </nav>
          </div>
          <div className="w-full xl:w-6/12">
            <strong className="block mb-4 mt-8 xl:mt-0">Mehr</strong>
            <nav>
              <ul>
                {more.map(item => (
                  <li className="mt-6" key={item.id}>
                    <a
                      className="link link-padded ease-in-out duration-150"
                      href={item.url}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        <div className="w-1/3 xl:w-1/4 xl:border-l border-white pl-16 -ml-16 box-content">
                <strong className="block mb-4">{contactBlock[0].headline}</strong>
          <p className="mt-6">{contactBlock[0].text}</p>
          <p className="mt-6 mb-6">{contactBlock[0].openingHours}</p>
          <div>
            <a
              href={`tel:${contactBlock[0].phoneNumber}`}
              className="link link-padded no ease-in-out duration-150"
            >
              <IosCall color="currentColor" className="inline mr-3" />
              {contactBlock[0].phoneNumber}
            </a>
          </div>
          <a
            href={`mailto:${contactBlock[0].email}`}
            className="link link-padded ease-in-out duration-150"
          >
            <IosAtOutline color="currentColor" className="inline mr-3" />
            {contactBlock[0].email}
          </a>
        </div>
      </div>
    </section>
  );
};

DesktopFooterMenu.propTypes = {
  footerContent: PropTypes.PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  more: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
};

export default DesktopFooterMenu;
