import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import LogoHeader from './LogoHeader';
import MobileHeaderMenu from './MobileHeaderMenu';

const MobileHeader = ({ article, headerContent }) => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        categories(group: "articleCategory") {
          ... on Gatsby_articleCategory_Category {
            title
            uri
            uid
          }
        }
        tools: entries(section: "tool") {
          ... on Gatsby_tool_tool_Entry {
            title
            uniformResourceLocator
            uid
            uri
          }
        }
        more: nodes(navHandle: "headerMore") {
          title
          url
          uid
        }
      }
    }
  `);

  const [visible, toggleVisibility] = useState(false);
  const [mounted, toggleMounted] = useState(false);
  const [hamburgerHidden, toggleHamburger] = useState(false);

  const toggle = () => {
    if (!mounted) {
      toggleMounted(true);
      toggleVisibility(true);
    } else {
      toggleVisibility(false);
      document.body.classList.remove('overflow-hidden');
      setTimeout(() => toggleMounted(false), 500);
    }
  };

  const { categories, more, tools } = data.gatsby;

  return (
    <div className="bg-white">
      <div className="flex items-center justify-between h-15 px-5 md:px-10">
        <LogoHeader />
        <button
          className="flex flex-col link-padded z-40 focus:outline-none"
          onClick={toggle}
        >
          <span
            className={classNames(
              {
                '-rotate-45 -translate-x-0.4 -translate-y-0.4': visible,
                'rotate-0': !visible,
                'opacity-0': hamburgerHidden,
              },
              'transform  origin-right duration-500 ease w-7 h-0.5 bg-black-900 mb-1.5 rounded-md'
            )}
          ></span>
          <span
            className={classNames(
              'transform duration-500 ease w-7 h-0.5 bg-black-900 mb-1.5 rounded-md',
              {
                'opacity-0 scale-0': visible,
                'opacity-1 scale-100': !visible,
                'opacity-0': hamburgerHidden,
              }
            )}
          ></span>
          <span
            className={classNames(
              {
                'rotate-45 -translate-x-0.4': visible,
                'rotate-0': !visible,
                'opacity-0': hamburgerHidden,
              },
              'transform  origin-right duration-500 ease w-7 h-0.5 bg-black-900 rounded-md'
            )}
          ></span>
        </button>
      </div>
      
      <div
          className='w-full m-auto max-w-screen-xxl bg-black-700.5 z-20'
          style={{ height: '1px' }}
      >
      </div>
      
      {mounted && (
        <MobileHeaderMenu
          categories={categories}
          more={more}
          tools={tools}
          headerContent={headerContent}
          visible={visible}
          hide={toggleHamburger}
          hidden={hamburgerHidden}
        />
      )}
    </div>
  );
};

export default MobileHeader;
