import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import DesktopHeaderExpanded from './DesktopHeaderExpanded'

const MainMenu = ({ article }) => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        categories(group: "articleCategory") {
          id
          ... on Gatsby_articleCategory_Category {
            title
            headline
            uri
            slug
            teaserArticles {
              ... on Gatsby_articles_articles_Entry {
                id
                title
                uri
                thumbnailSmall {
                  url
                }
                articleAuthor {
                  ... on Gatsby_articleAuthor_articleAuthor_Entry {
                    title
                  }
                }
              }
            }
            slug
          }
          id
        }
      }
    }
  `)

  const [currentCategory, switchCategory] = useState(null)
  const [isHovered, toggleHovered] = useState(false)

  const { categories } = data.gatsby

  return (
    <div
      onMouseEnter={() => toggleHovered(true)}
      onMouseLeave={() => toggleHovered(false)}
      role="presentation"
    >
      <ul className="flex items-center">
        {categories.map(category => (
          <li key={category.id}>
            <Link
              activeClassName="border-blue-900"
              className="border-solid border-b-6 border-transparent hover:border-blue-500 pt-1.5 flex cursor-pointer items-center h-20 focus:outline-none focus:shadow-none md:text-15 md:px-3 xl:px-5 xl:text-base"
              to={`/${category.slug}`}
              onMouseMove={() => switchCategory(category.slug)}
              aria-label={category.title}
            >
              {category.title}
            </Link>
          </li>
        ))}
      </ul>
      {isHovered && currentCategory && (
        <DesktopHeaderExpanded
          category={categories.filter(
            category => category.slug === currentCategory
          )}
          article={article}
        />
      )}
    </div>
  )
}

export default MainMenu
