import { useEffect } from 'react';

let lastScrollTop;

const useScrollDirection = fn => {
  if (typeof window !== undefined && typeof document !== undefined) {
    lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  } else {
    lastScrollTop = 0;
  }

  useEffect(() => {
    const determineScrollDirection = () => {
      if (typeof document !== undefined && typeof window !== undefined) {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (st <= 0) {
          lastScrollTop = 0;
        } else if (
          st >=
          document.body.clientHeight - document.documentElement.clientHeight
        ) {
          lastScrollTop =
            document.body.clientHeight - document.documentElement.clientHeight;
        } else {
          fn(st < lastScrollTop);
          lastScrollTop = st;
        }
      }
    };

    if (typeof window !== undefined) {
      window.addEventListener('scroll', determineScrollDirection, false);
    }

    return () => {
      window.removeEventListener('scroll', determineScrollDirection, false);
    };
  }, [fn]);
};

export default useScrollDirection;
