import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import IosClose from 'react-ionicons/lib/IosClose'
import IosCheckmark from 'react-ionicons/lib/IosCheckmark'

const FormWithEmailInput = ({ url, wide }) => {
  const [success, toggleSuccess] = useState(false)

  const displayIcon = () => {
    if (
      formik.touched.email &&
      formik.errors.email &&
      !formik.isSubmitting &&
      !success
    )
      return <IosClose color="white" fontSize="32" />

    if (!formik.isSubmitting && success)
      return <IosCheckmark color="white" fontSize="38" />

    if (!success) return <IosArrowForward color="white" />
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      terms: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Ungültige E-Mail Adresse')
        .max(50, 'E-Mail Adresse muss weniger als 50 Zeichen enthalten')
        .required('E-Mail Adresse erforderlich'),
      terms: Yup.boolean().oneOf(
        [true],
        'Sie müssen den Datenschutzbestimmungen zustimmen'
      ),
    }),

    onSubmit: async values => {
      try {
        toggleSuccess(false)
        const date = new Date()

        const rawRes = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(
            { ...values, timestamp: date.toISOString() },
            null,
            2
          )
        })

        const res = await rawRes.json()

        if (res.status === 'success') toggleSuccess(true)
      } catch (error) {
        formik.errors.email =
          'Something went wrong. Please, try resubmitting the form'
      }
    }
  })

  return (
    <form className="w-full mr-auto" onSubmit={formik.handleSubmit} noValidate>
      <div className="flex">
        <input
          id="email"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={e => {
            formik.handleChange(e)
            toggleSuccess(false)
          }}
          className={`w-full p-2 text-15 rounded-l-md rounded-r-none border-2 active:border-blue-200 focus:outline-none shadow-none focus:shadow-outline-none focus:border-blue-500 sm:${
            wide ? '' : 'w-64'
          } ${formik.touched.email &&
            formik.errors.email &&
            'text-red'} email-input ${
            formik.isSubmitting
              ? 'cursor-not-allowed border-gray-500'
              : 'border-blue-900'
          }`}
          placeholder="E-Mail"
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
        />
        <button
          type="submit"
          className={`border-none w-10 flex justify-center items-center rounded-r-md rounded-l-none focus:outline-none transform transition-all ease-in-out duration-150 ${
            formik.isSubmitting
              ? 'cursor-not-allowed hover:bg-gray-500 bg-gray-500'
              : 'hover:bg-blue-500 bg-blue-900 focus:bg-blue-200'
          }`}
          disabled={formik.isSubmitting}
        >
          {displayIcon()}
        </button>
      </div>
      {formik.touched.email && formik.errors.email && (
        <p className="mt-1 text-13 text-red">{formik.errors.email}</p>
      )}
      
      <div className="checkbox-container relative grid grid-cols-1 mt-2">
        <input
          id="termns"
          name="terms"
          type="checkbox"
          value={formik.values.terms}
          onBlur={formik.handleBlur}
          disabled={formik.isSubmitting}
          onChange={e => {
            formik.handleChange(e)
            toggleSuccess(false)
          }}
        />
        <label htmlFor="terms" className="w-4/5 sm:w-auto text-13 text-gray-500 ml-8">
          {'Ich stimme den '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://nachfolge.de/datenschutzerklaerung"
            className="underline transform duration-150 ease-in-out transition-colors hover:text-gray-600"
          >
            Datenschutzerklärungen
          </a>
          {' zu.*'}
        </label>
        {formik.errors.terms && formik.touched.terms ? (
          <p className="text-red text-13 mt-1">{formik.errors.terms}</p>
        ) : null}
      </div>
      
      {success && !formik.errors.email && (
        <p className="mt-1 text-13 text-green">
          *Sie haben sich erfolgreich angemeldet!
        </p>
      )}
    </form>
  )
}

FormWithEmailInput.propTypes = {
  url: PropTypes.string.isRequired,
  wide: PropTypes.bool
}

export default FormWithEmailInput
