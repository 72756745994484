import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

const TertiaryButton = props => {
  return (
    <button
      {...props}
      className={classnames(
        {
          'text-grey-400 pointer-events-none': props.disabled,
          'text-blue-900 hover:text-blue-500 active:text-blue-200 focus:text-blue-200': !props.diabled
        },
        'text-15 flex focus:outline-none focus:shadow-none transition-all duration-200 ease-in-out',
        props.className
      )}
    >
      <span className="mr-1 uppercase inline-block">{props.children}</span>
      <IosArrowForward color="currentColor" />
    </button>
  )
}

TertiaryButton.propTypes = {
  action: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default TertiaryButton
