import React from 'react'

import LogoHeader from './LogoHeader'
import MainMenu from './MainMenu'
import MoreLink from './MoreLink'
import Searchbar from './Searchbar'

const DesktopHeaderDefault = ({ headerContent }) => {

  return (
    <section className="h-20 bg-white px-10 relative" id="desktop-header">
      <div className="flex w-full items-center header-shadow h-full border-b border-black-700.5 m-auto max-w-screen-xxl">
        <LogoHeader className="lg:mr-6 xl:mr-10" />
        <nav className="flex w-full items-center">
          <MainMenu />
          <div className="border-grey-400 h-6 border-l-2 md:ml-3 xl:ml-5" />
          <MoreLink />
          <Searchbar className="hidden ml-auto xxl:flex" />
        </nav>
      </div>
    </section>
  )
}

export default DesktopHeaderDefault
