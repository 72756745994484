import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IosArrowDown from 'react-ionicons/lib/IosArrowDown'
import IosArrowUp from 'react-ionicons/lib/IosArrowUp'

const MobileFooterExpander = ({ children, title }) => {
  const [visible, toggleVisibility] = useState(false)
  const [mounted, mount] = useState(false)

  const toggle = () => {
    if (!mounted) {
      mount(true)
      toggleVisibility(true)
    } else {
      toggleVisibility(false)
      setTimeout(() => mount(false), 300)
    }
  }

  return (
    <div className="border-b border-white">
      <button
        className="w-full flex cursor-pointer focus:outline-none"
        onClick={toggle}
      >
        <p className="text-white mr-auto py-5 font-bold text-left">{title}</p>
        <span className="ml-auto py-5">
          {mounted ? (
            <IosArrowUp color="white" fontSize="24px" className="mr-1" />
          ) : (
            <IosArrowDown color="white" fontSize="24px" className="mr-1" />
          )}
        </span>
      </button>

      {mounted && (
        <div
          className={`transform transition-all duration-300 ease-in-out ${
            visible ? 'menu-item max-h-500' : 'menu-item max-h-0 opacity-0'
          }`}
        >
          <div className="pb-5">{children}</div>
        </div>
      )}
    </div>
  )
}

MobileFooterExpander.propTypes = {
  title: PropTypes.string.isRequired
}

export default MobileFooterExpander
