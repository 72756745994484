export const isTouchDevice = () => {
  if (typeof window !== 'undefined') {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')

    const mq = query => window.matchMedia(query).matches

    if (
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch)
    ) {
      return true
    }

    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
      ''
    )

    return mq(query)
  } else return undefined
}

export const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}
