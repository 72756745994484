import React from 'react'

import DesktopHeaderArticle from './DesktopHeaderArticle'
import DesktopHeaderDefault from './DesktopHeaderDefault'

const DesktopHeader = ({ article, title, url, headerContent }) => {
  return article ? (
    <DesktopHeaderArticle article title={title} url={url} headerContent={headerContent} />
  ) : (
    <DesktopHeaderDefault headerContent={headerContent} />
  )
}

export default DesktopHeader
