import React, { useState } from 'react'
import classNames from 'classnames'

import LogoHeader from './LogoHeader'
import MainMenu from './MainMenu'
import ModalTrigger from '../HOC/ModalTrigger'
import MoreLink from './MoreLink'
import TertiaryButton from './UIComponents/TertiaryButton'

import useProgressBar from '../hooks/useProgressBar'
import useScrollDirection from '../hooks/useScrollDirection'

const DesktopHeaderArticle = ({ title, url, headerContent }) => {
  const { modalBlock } = headerContent
  const [scrollingUp, setScrollDirection] = useState(true)
  const [visible, toggleVisibility] = useState(true)

  const toggle = bool => {
    if (bool === scrollingUp) return
    toggleVisibility(bool)
    setTimeout(() => setScrollDirection(bool), 200)
  }

  useScrollDirection(toggle)
  useProgressBar()

  return (
    <section className="h-20 bg-white relative px-10" id="desktop-header">
      <nav className="flex w-full items-center header-shadow h-full m-auto max-w-screen-xxl">
        <LogoHeader className="lg:mr-6 xl:mr-10" />
        {scrollingUp ? (
          <div
            className={classNames(
              'flex w-full items-center transition-all duration-200',
              {
                'opacity-100': visible,
                'opacity-0': !visible
              }
            )}
          >
            <MainMenu article />
            <div className="border-grey-400 h-6 border-l-2 md:ml-3 xl:ml-5" />
            <MoreLink />
          </div>
        ) : (
          <div
            className={classNames(
              'flex items-center transform transition-all duration-200',
              {
                'opacity-100': !visible,
                'opacity-0': visible
              }
            )}
          >
            <p className="md:px-3 xl:px-5 mr-5">{title}</p>
          </div>
        )}
        <ModalTrigger
          className="hidden ml-auto xl:flex"
          form
          url={"https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"}
          title={modalBlock[0].modalTitle}
          headline={modalBlock[0].modalHeadline}
          text={modalBlock[0].modalText}
        >
          <TertiaryButton>{modalBlock[0].modalTitle}</TertiaryButton>
        </ModalTrigger>
      </nav>
      <div
        className="w-full m-auto max-w-screen-xxl bg-black-700.5 z-20"
        style={{ height: '1px' }}
      >
        <div
          className="bg-cyan-600 max-w-screen-xxl w-0"
          id="progress-bar"
          style={{ height: '4px' }}
        />
      </div>
    </section>
  )
}

export default DesktopHeaderArticle
