import React from 'react';
import { Helmet } from 'react-helmet';
import '../webfonts/fonts.css';

function Fonts() {
  return (
    <Helmet>
      <link rel="preload" as="style" href="https://fast.fonts.net/lt/1.css?apiType=css&c=cecaee13-441e-49dd-977e-a7002d470500&fontids=1475946,1475958,1475964,1475970" />
      <link rel="stylesheet" media="all" href="https://fast.fonts.net/lt/1.css?apiType=css&c=cecaee13-441e-49dd-977e-a7002d470500&fontids=1475946,1475958,1475964,1475970" />
    </Helmet>
  );
}

export default Fonts;