import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ModalTrigger from '../HOC/ModalTrigger';
import TertiaryButton from './UIComponents/TertiaryButton';

const MobileHeaderMenu = ({
  categories,
  hide,
  hidden,
  headerContent,
  more,
  visible,
}) => {
  const { modalBlock } = headerContent
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      return document.body.classList.remove('overflow-hidden');
    };
  }, []);
  return (
    <div
      className={classnames({
        'w-screen h-screen overflow-auto scrolling-touch shadow-2xl px-5 py-15 md:px-10 transform transition-all duration-500 ease-in-out mobile-header-menu fixed top-0 bg-white translate-x-0': true,
        'translate-x-full': !visible,
      })}
      id="mobile-header-menu"
    >
      <hr className="h-px relative w-auto border-none bg-black-700.5" />
      <div className="pb-3">
        <div className="pt-5 pb-4 border-b border-black-700.5 sm:pt-6 sm:pb-5">
          <strong className="sm:text-2xl">Themen</strong>
          <nav>
            <ul className="my-2">
              {categories.map(category => (
                <li className="mt-1" key={category.uid}>
                  <Link
                    className="link link-padded sm:text-xl"
                    to={`/${category.uri}`}
                  >
                    {category.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="mt-5 pb-4 border-b border-black-700.5 sm:mt-6 sm:pb-5">
          <strong className="sm:text-2xl">Mehr</strong>
          <nav>
            <ul className="my-2">
              {more.map(item => (
                <li className="mt-1" key={item.uid}>
                  <a className="link link-padded sm:text-xl" href={item.url}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="mt-6 mb-20">
          <nav>
            <ul>
              <li onClick={() => hide(!hidden)} role="presentation">
                <ModalTrigger
                  form
                  url={"https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"}
                  title={modalBlock[0].modalTitle}
                  headline={modalBlock[0].modalHeadline}
                  text={modalBlock[0].modalText}
                >
                  <TertiaryButton>{modalBlock[0].modalTitle}</TertiaryButton>
                </ModalTrigger>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

MobileHeaderMenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  more: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uniformResourceLocator: PropTypes.string.isRequired,
    })
  ),
};

export default MobileHeaderMenu;
