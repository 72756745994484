import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import DesktopFooterMenu from './DesktopFooterMenu'
import MobileFooterMenu from './MobileFooterMenu'
import Logo from './Logo'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        footerContent: globalSet (handle: "Footer") {
          ... on Gatsby_footer_GlobalSet {
            contactBlock {
              ... on Gatsby_contactBlock_contactblock_BlockType {
                headline
                text
                phoneNumber
                email
                openingHours
              }
            }
            modalBlock {
              ... on Gatsby_modalBlock_modal_BlockType {
                modalHook
                modalTitle
                modalHeadline
                modalText
              }
            }
          }
        }
        categories(group: "articleCategory") {
          ... on Gatsby_articleCategory_Category {
            title
            uri
          }
          id
        }
        tools: entries(section: "tool") {
          ... on Gatsby_tool_tool_Entry {
            title
            uri
            uniformResourceLocator
          }
          id
        }
        legal: entries(section: "legal") {
          id
          title
          uri
        }
        more: nodes(navHandle: "footer") {
          title
          url
          id
        }
      }
    }
  `)

  const { categories, more, tools, footerContent } = data.gatsby

  // We somehow don't get our data from the CMS, so we have a workaround for these.
  const legal = [
    {
      id: 1,
      title: "Impressum",
      uri: "https://www.carlfinance.de/impressum"
    },
    {
      id: 2,
      title: "Datenschutz",
      uri: "https://www.carlfinance.de/datenschutz"
    }
  ];

  return (
    <footer className="text-white" id="footer">
      <MobileFooterMenu categories={categories} tools={tools} more={more} footerContent={footerContent} />
      <DesktopFooterMenu categories={categories} tools={tools} more={more} footerContent={footerContent}/>
      <section className="bg-black-900 px-5 md:px-12 lg:px-24 xl:px-5 h-15">
        <div className="flex items-center justify-between m-auto max-w-screen-xl text-xs md:text-sm">
        
          <div className="items-center hidden md:flex">
            <a
              href="https://carlfinance.de"
              className="link block w-12"
              title="Carl Finance Homepage"
            >
              <Logo />
            </a>
            <span className="ml-6">&copy; Carl Finance GmbH {new Date().getFullYear()}</span>
          </div>

          <nav className="h-15 flex items-center">
            <ul className="flex">
              {legal.map(item => (
                <li className="mr-6 md:mr-8" key={item.id}>
                  <Link className="link link-padded" to={`${item.uri}`}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  gatsby: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired
      })
    ),
    legal: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired
      })
    ),
    more: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ),
    tools: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        uniformResourceLocator: PropTypes.string.isRequired
      })
    )
  })
}

export default Footer
