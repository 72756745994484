import { useEffect } from 'react'

const useProgressBar = () => {
  useEffect(() => {
    const setWidth = () => {
      if (typeof document !== 'undefined') {
        let winScroll = window.scrollY
        const bodyHeight =
          document.body.clientHeight - document.documentElement.clientHeight
        let scrolled = (winScroll / bodyHeight) * 100
        document.getElementById('progress-bar').style.width = `${scrolled}%`
      }
    }

    if (typeof document !== 'undefined') {
      window.addEventListener('scroll', setWidth)
    }
    return () => {
      window.removeEventListener('scroll', setWidth)
    }
  }, [])
}

export default useProgressBar
