import React from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql, Link } from 'gatsby';

import ModalTrigger from '../HOC/ModalTrigger';
import TertiaryButton from './UIComponents/TertiaryButton';

const MoreLink = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        more: nodes(navHandle: "footer") {
          title
          url
          id
        }
      }
    }
  `);

  const { more } = data.gatsby;

  return (
    <div className="relative">
      <div className="group">
        <span
          className={classNames(
            {
              'cursor-pointer border-solid border-b-6 hover:border-blue-500 border-transparent pt-1.5 flex items-center focus:outline-none focus:shadow-none h-20 md:text-15 md:px-3 xl:px-5 md:mx-3 xl:mx-5 xl:text-base group': true,
            },
            className
          )}
        >
          Mehr
        </span>
        <div
          className="absolute shadow-lg hidden top-auto bg-white left-0 py-10 px-6 rounded-b-sm group-hover:flex w-56 xl:px-10"
          style={{ top: '80px' }}
        >
          <ul className="flex flex-col w-full">
            <div>
              <li className="mb-6 list-none w-full">
                <Link
                  className="inline-block link link-padded ease-in-out duration-150"
                  to="/funktionen"
                  aria-label="Funktionen"
                >
                  Unser Angebot
                </Link>
              </li>
              {more.map(item => (
                <li className="mb-6 xl:last:mb-0 list-none w-full" key={item.id}>
                  <Link
                    className="inline-block link link-padded ease-in-out duration-150"
                    to={item.url}
                    aria-label={item.title}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </div>
            <li className="xl:hidden">
              <hr className="h-px mb-6 border-none bg-black-700.5 " />
              <ModalTrigger
                form
                url="https://hooks.zapier.com/hooks/catch/8845339/bmk0grk"
                title="Newsletter"
                headline="Verpassen Sie keine Beiträge mehr!"
                text="Sie wollen regelmäßig spannende Artikel rund um das Thema Nachfolge lesen? Dann melden Sie sich hier für unseren kostenlosen Newsletter und verpassen Sie keine Beiträge mehr. Es erwarten Sie unter anderem nützliche Tipps, Inspirationen, Anekdoten bzw. Erfolgsgeschichten, Erklärungen wichtiger Fachbegriffe sowie Studien und umfangreiche Marktberichte. Sie können den E-Mail-Newsletter jederzeit wieder abbestellen."
              >
                <TertiaryButton>Newsletter</TertiaryButton>
              </ModalTrigger>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MoreLink;
