import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import IosCall from 'react-ionicons/lib/IosCall';
import IosAtOutline from 'react-ionicons/lib/IosAtOutline';
import MobileFooterExpander from './MobileFooterExpander';
import ModalTrigger from '../HOC/ModalTrigger';

const MobileFooterMenu = ({ categories, more, tools, footerContent }) => {
  const { contactBlock, modalBlock } = footerContent

  return (
    <section className="bg-black-700 px-5 pt-3 pb-8 md:hidden">
      <MobileFooterExpander title="Themen">
        <nav>
          <ul className="-mt-3 mb-3">
            {categories.map(category => (
              <li className="mt-4" key={category.id}>
                <Link to={`/${category.uri}`}>{category.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </MobileFooterExpander>
      <MobileFooterExpander title="Unser Angebot">
        <nav>
          <ul className="-mt-3 mb-3">
            {tools.map(tool => (
              <li className="mt-4" key={tool.id}>
                <a href={tool.uniformResourceLocator}>{tool.title}</a>
              </li>
            ))}
            <li className="mt-4">
              <ModalTrigger
                form
                url={modalBlock[0].modalHook}
                title={modalBlock[0].modalTitle}
                headline={modalBlock[0].modalHeadline}
                text={modalBlock[0].modalText}
              >
                <span>{modalBlock[0].modalTitle}</span>
              </ModalTrigger>
            </li>
          </ul>
        </nav>
      </MobileFooterExpander>
      <MobileFooterExpander title="Mehr">
        <nav>
          <ul className="-mt-3 mb-3">
            {more.map(item => (
              <li className="mt-4" key={item.id}>
                <a href={item.url}>{item.title}</a>
              </li>
            ))}
          </ul>
        </nav>
      </MobileFooterExpander>
      <MobileFooterExpander title="Sie haben Fragen?">
        <div className="mt-2 mb-3">
          <p>{contactBlock[0].text}</p>
          <p className="mt-6 mb-6">{contactBlock[0].openingHours}</p>
          <div>
            <a href={`tel:${contactBlock[0].phoneNumber}`}>
              <IosCall color="white" className="inline mr-3" />
              {contactBlock[0].phoneNumber}
            </a>
          </div>
          <a href={`mailto:${contactBlock[0].email}`}>
            <IosAtOutline color="white" className="inline mr-3" />
            {contactBlock[0].email}
          </a>
        </div>
      </MobileFooterExpander>
    </section>
  );
};

MobileFooterMenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  more: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
};

export default MobileFooterMenu;
