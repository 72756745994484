import React, { useState } from 'react'
import classNames from 'classnames';
import { useStaticQuery, graphql, Link } from 'gatsby';

import SearchIcon from '../assets/icons/icon-search.svg';

const Searchbar = props => {
  const data = useStaticQuery(graphql`
    query {
      gatsby {
        articles: entries(section: "articles") {
            ... on Gatsby_articles_articles_Entry {
              id
              readingTime
              lastRevision
              postDate
              dateCreated
              slug
              title
              uri
              url
              thumbnailSmall {
                url
              }
              articleAuthor {
                ... on Gatsby_articleAuthor_articleAuthor_Entry {
                  nameField
                  phone
                  email
                  excerpt
                  uri
                  position
                  avatar {
                    url
                  }
                }
              }
            }
        }
      }
    }
  `)

  const { articles } = data.gatsby

  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const handleInputChange = event => {
    const query = event.target.value

    const filteredData = articles.filter(article => {
        const { title } = article
        return (
          title.toLowerCase().includes(query.toLowerCase())
        )
    })

    setState({
        query,
        filteredData,
      })
  }

  const { filteredData, query } = state

  return (
    <div className={props.className}>
      <div className="relative">
        <div className="group md:pr-3 xl:pr-5">
          <span
            className={classNames(
              {
                'cursor-pointer border-solid border-b-6 border-transparent pt-1.5 flex items-center focus:outline-none focus:shadow-none h-20 md:text-15 xl:text-base group': true,
              },
            )}
          >
              <div className="relative ">
                  <input
                      placeholder="Artikel suchen"
                      type="search"
                      className="appearance-none-all text-black-900 w-full bg-grey-300 border-2 border-grey-300 p-2 text-15 rounded focus:outline-none focus:shadow-none focus:border-blue-200 transition-all duration-200 md:px-3 xl:px-5 md:mx-3 xl:mx-5"
                      onChange={handleInputChange}
                  />
                  { query.length === 0 ?
                      <div className="absolute inset-y-0 right-0 pt-2">
                          <SearchIcon/>
                      </div> 
                  : null }
              </div>
          </span>
          {query ? 
              <div
                  className="absolute flex shadow-lg top-auto bg-white left-0 py-10 rounded-b-sm w-64  xl:px-5 md:mx-6 xl:mx-5"
                  style={{ top: '80px' }}
              >
                  {filteredData.length > 0 ? 
                      <ul className="flex flex-col w-full">
                          <div>
                              {filteredData.slice(0, 5).map(item => (
                              <li className="mb-6 xl:last:mb-0 list-none w-full" key={item.id}>
                                  <Link
                                  className="inline-block link  ease-in-out duration-150 "
                                  to={item.url}
                                  aria-label={item.title}
                                  >
                                  {item.title}
                                  </Link>
                              </li>
                              ))}
                          </div>
                      </ul>
                  : <div className="w-full">Keine Ergebnisse</div>}
              </div>
              : null
          }
          
        </div>
      </div>
    </div>
  );
}

export default Searchbar
