import React from 'react'

const Logo = () => {
  return (
    <svg viewBox="0 0 46.05 13.35">
      <path
        fill="currentColor"
        d="M6.37 13.35A6.68 6.68 0 016.37 0a5.95 5.95 0 015.99 4.26H9.65a3.63 3.63 0 00-3.28-2.03C4.2 2.23 2.5 4.26 2.5 6.67c0 2.44 1.7 4.47 3.87 4.47a3.68 3.68 0 003.28-2.07h2.69a6.03 6.03 0 01-5.97 4.28"
      />
      <path
        fill="currentColor"
        d="M18.36 2.96l-1.85 5.3h3.7zm-2.64 7.58l-.9 2.58h-2.67L16.77.22h3.19l4.62 12.9H21.9l-.9-2.58z"
      />
      <path
        fill="currentColor"
        d="M31.28 5.9c.32 0 1.96-.14 1.96-1.74 0-.42 0-1.69-2.02-1.69H27.7V5.9zm4.39 7.18H33.1a3.52 3.52 0 01-.27-1.41V9.7c0-1.1-.53-1.6-1.71-1.6h-3.44v4.98h-2.52V.2h6.05c2.77 0 4.55 1.43 4.55 3.84a3 3 0 01-1.86 2.94 2.84 2.84 0 011.45 2.75v1.8a5.05 5.05 0 00.3 1.56"
      />
      <path fill="currentColor" d="M36.98.19v12.9h9.07v-2.3h-6.53V.2z" />
      <path fill="currentColor" d="M42.2.19l3.85 3.86V.2z" />
    </svg>
  )
}

export default Logo
