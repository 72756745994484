import React from 'react'
import classNames from 'classnames'

import DesktopHeaderExpandedGrid from './DesktopHeaderExpandedGrid'

const DesktopHeaderExpanded = ({ article, category }) => {
  return (
    <section
      className="fixed left-0 bg-white w-screen"
      style={{
        top: '80px'
      }}
    >
      <div className="px-10 h-full">
        <div
          className={classNames(
            { 'border-t': article },
            'mx-auto h-full max-w-screen-xxl border-b border-black-700.5 py-16'
          )}
        >
          <DesktopHeaderExpandedGrid category={category} />
        </div>
      </div>
    </section>
  )
}

export default DesktopHeaderExpanded
