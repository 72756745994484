import React from 'react'
import { Link } from 'gatsby'

import FakeTertiaryLink from './UIComponents/FakeTertiaryLink'

const DesktopHeaderExpandedGrid = ({ category }) => {
  const { title, headline, uri, teaserArticles } = category[0]

  return (
    <div className="mx-auto h-auto max-w-screen-xxl overflow-hidden grid grid-cols-4 gap-8 xl:gap-12 break-words">
      <div className="flex flex-col">
        <h3 className="mb-4 text-xl xl:text-23">{title}</h3>
        <p className="mb-6 w-4/5">{headline}</p>
        <Link to={`/${uri}`} className="button-primary" aria-label={title}>
          Zur Kategorie
        </Link>
      </div>
      {teaserArticles &&
        teaserArticles.map(article => (
          <Link
            to={`/${article.uri}`}
            key={article.id}
            className="flex flex-col self-start group"
            aria-label={article.title}
          >
            <img
              src={article.thumbnailSmall[0].url}
              alt={article.title}
              loading="lazy"
            />
            <h3 className="text-xl xl:text-23 mt-3 xl:mt-4">{article.title}</h3>
            <div className="overflow-hidden">
              <FakeTertiaryLink
                text="Weiterlesen"
                className="mr-auto mt-3 transform translate-y-5 group-hover:translate-y-0 xl:mt-4"
              />
            </div>
          </Link>
        ))}
    </div>
  )
}

export default DesktopHeaderExpandedGrid
